import React, { lazy } from 'react';
const WebsiteDesign = lazy(() => import('./pages/services/WebsiteDesign'));
const AppDevelopment = lazy(() => import('./pages/services/AppDevelopment'));
const SoftwareDevelopment = lazy(() => import('./pages/services/SoftwareDevelopment.jsx'));
const UIUX = lazy(() => import('./pages/services/UIUX.jsx'));
const EcommerceCms = lazy(() => import('./pages/services/EcommerceCms.jsx'));
const DevopsConsulting = lazy(() => import('./pages/services/DevopsConsulting.jsx'));
const Error = lazy(() => import('./pages/Error'));
const BookCall = lazy(() => import('./pages/BookCall'));
const CueWorkz = lazy(() => import('./pages/Workz'));
const CueClimb = lazy(() => import('./pages/CueClimb'));
const Products = lazy(() => import('./pages/Products'));
const HomePage = lazy(() => import('./pages/HomePage'));
const Portfolio = lazy(() => import('./pages/Portfolio'));
const About = lazy(() => import('./pages/About'));
const ContactUs = lazy(() => import('./pages/ContactUs'));
const routes = [
    {
        path: '/',
        element: <HomePage />,
    },
    {
        path: '/contact-us',
        element: <ContactUs />,
    },
    {
        path: '/about-us',
        element: <About />,
    },
    {
        path: '/portfolio',
        element: <Portfolio />,
    },
    {
        path: '/products',
        element: <Products />,
    },
    {
        path: '/book-a-call',
        element: <BookCall />,
    },
    {
        path: '/cueworkz',
        element: <CueWorkz />,
    },
    {
        path: '/climb',
        element: <CueClimb />,
    },
    {
        path: '/website-design-redesign',
        element: <WebsiteDesign />,
    },
    {
        path: '/custom-software-development',
        element: <SoftwareDevelopment />,
    },
    {
        path: '/android-ios-app-development',
        element: <AppDevelopment />,
    },
    {
        path: '/uiux-enhancement',
        element: <UIUX />,
    },
    {
        path: '/e-commerce-cms',
        element: <EcommerceCms />,
    },
    {
        path: '/devops-consulting',
        element: <DevopsConsulting />,
    },
    {
        path: '*',
        element: <Error />,
    },
];
export default routes;
